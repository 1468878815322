import React from 'react';

import detectIsMobile from 'ismobilejs';

export const ROOT_URL = process.env.REACT_APP_API_ROOT_URL;
export const BASE_URL = ROOT_URL+'/api/1';
//  process.env.REACT_APP_BRANCH_NAME === 'master'
//    ? 'https://api.voxm.live/api/1'
//    : process.env.REACT_APP_BRANCH_NAME
//    ? 'https://api-staging.voxm.live/api/1'
//    : 'http://debian.voxm:5000/api/1';

export const PERM_SHARE_URL = ROOT_URL+'/p/';
//export const PERM_SHARE_URL = `https://${
//  process.env.REACT_APP_BRANCH_NAME !== 'master' ? 'staging.' : ''
//}voxm.io/p/`;

export const THREAD_SHARE_URL = ROOT_URL+'/t/';
//export const THREAD_SHARE_URL = `https://${
//  process.env.REACT_APP_BRANCH_NAME !== 'master' ? 'staging.' : ''
//}voxm.io/t/`;


export const VOICE_SHARE_URL = ROOT_URL+'/s/';
//export const VOICE_SHARE_URL = `https://api${
//  process.env.REACT_APP_BRANCH_NAME !== 'master' ? '-staging' : ''
//}.voxm.live/s/`;

export const API_ENDPOINTS = {
  appUsers: `${BASE_URL}/app_users/`,
  appUsersCounts: `${BASE_URL}/app_users/counts/`,
  auth: `${BASE_URL}/user/login/`,
  authToken: `${BASE_URL}/user/token_login/`,
  register: `${BASE_URL}/user/register/`,
  currentUser: `${BASE_URL}/users/current/`,
  channels: `${BASE_URL}/channels/`,
  channelsCounts: `${BASE_URL}/channels/counts/`,
  channelList: `${BASE_URL}/channels/channel_list/`,
  channelInteractions: `${BASE_URL}/interactions/admin/`,
  channelInteractionsRandom: `${BASE_URL}/interactions/admin/random/`,
  channelInteractionsActions: `${BASE_URL}/interactions/admin/actions`,
  interactionCounts: `${BASE_URL}/interactions/counts/`,
  statistics: `${BASE_URL}/statistics/`,
  unreadMessages: `${BASE_URL}/statistics/messages_not_read`,
  widgets: `${BASE_URL}/widgets/`,
  widgetsCounts: `${BASE_URL}/widgets/counts/`,
  diffusionSpaces: `${BASE_URL}/diffusion_spaces/`,
  diffusionBlocks: `${BASE_URL}/diffusion_blocks/`,
  diffusionSpacesCounts: `${BASE_URL}/diffusion_spaces/counts/`,
  helpItems: `${BASE_URL}/help_item/`,
  helpItemsComments: `${BASE_URL}/help_item/comments/`,
  helpItemsCounts: `${BASE_URL}/help_item/counts/`,
  users: `${BASE_URL}/users/`,
  usersReset: `${BASE_URL}/user/reset/`,
  usersResend: `${BASE_URL}/user/resend/`,
  usersCounts: `${BASE_URL}/users/counts/`,
  interactions: `${BASE_URL}/interactions/`,
  interactionReply: `${BASE_URL}/interactions/reply/`,
  interactionRead: `${BASE_URL}/interactions/read/`,
  segmentNew: `${BASE_URL}/show_segments/new/`,
  usersNew: `${BASE_URL}/user/signup/`,
  showSegments: `${BASE_URL}/show_segments/`,
  welcomeHelpItem: `${BASE_URL}/help_item/welcome/`,
  upload: `${BASE_URL}/upload/`,
  task: (id) => `${BASE_URL}/tasks/${id}/`,
  channelDetails: (id) => `${BASE_URL}/channels/${id}/`,
  channelDetailsWidget: (id) => `${BASE_URL}/channels/mobile/${id}/`,
  channelLogoUpload: (id) => `${BASE_URL}/channels/${id}/logo`,
  channelBackgroundUpload: (id) => `${BASE_URL}/channels/${id}/background`,
  interaction: (id) => `${BASE_URL}/interactions/${id}/`,
  interactionWithReplies: (id) => `${BASE_URL}/interactions/${id}/replies/`,
  interactionFtp: (id) => `${BASE_URL}/interactions/${id}/ftp/`,
  interactionAction: (id) => `${BASE_URL}/interactions/${id}/action/`,
  channelTimeline: (id) => `${BASE_URL}/permanent/timeline/${id}/`,
  segments: (id) => `${BASE_URL}/${id}/show_segments/`,
  segment: (id) => `${BASE_URL}/show_segments/${id}/`,
  segment_duplicate: (id) => `${BASE_URL}/show_segments/${id}/duplicate`,
  segment_export: (id) => `${BASE_URL}/show_segments/${id}/export`,
  getTemplatesShowSegmentsCategory: (category) => `${BASE_URL}/templates_show_segments/${category}`,
  getTemplatesShowSegmentsCategoryAndChannel: (category, channelId) => `${BASE_URL}/templates_show_segments/${category}/${channelId}/`,
  getTemplateShowSegmentByWidgetModel: (widgetModel) => `${BASE_URL}/templates_show_segments/widget_model/${widgetModel}/`,
  interactions_export: (id, filter) => `${BASE_URL}/${id}/show_segments/export?status=${filter}`,
  segmentBackgroundUpload: (id) =>
    `${BASE_URL}/show_segments/${id}/background/`,
  segmentBackgroundUploadGoogle: (id) =>
    `${BASE_URL}/show_segments/${id}/background_google/`,
  segmentVoteResults: (id) => `${BASE_URL}/show_segments/${id}/vote-results/`,
  segmentsCounts: (id) =>
    `${BASE_URL}/${id ? `${id}/` : ''}show_segments/counts/`,
  appUser: (id) => `${BASE_URL}/app_users/id/${id}/`,
  widget: (id) => `${BASE_URL}/widgets/${id}/`,
  diffusionSpace: (id) => `${BASE_URL}/diffusion_spaces/${id}/`,
  diffusionBlock: (id) => `${BASE_URL}/diffusion_blocks/${id}/`,
  helpItem: (id) => `${BASE_URL}/help_item/${id}/`,
  helpItemComment: (id) => `${BASE_URL}/help_item/comment/${id}/`,
  helpItemImageUpload: (id) => `${BASE_URL}/help_item/${id}/image/`,
  helpItemVideoUpload: (id) => `${BASE_URL}/help_item/${id}/video/`,
  user: (id) => `${BASE_URL}/users/${id}/`,
  userImageUpload: (id) => `${BASE_URL}/users/${id}/image/`,
  //  Widget
  permanentAll: (channelId) =>
    `${BASE_URL}/permanent/all/${channelId}/?active_only=true`,
  registerAppUser: `${BASE_URL}/app_users/register/`,
  loginAppUser: `${BASE_URL}/app_users/login/`,
  checkNickname: (nickname) => `${BASE_URL}/app_users/nickname/${nickname}/`,
  checkEmail: (email) => `${BASE_URL}/app_users/email/${email}/`,
  forgotEmail: `${BASE_URL}/app_users/reset/`,
  appUserMessages: (appUserId) => `${BASE_URL}/interactions/my/${appUserId}/`,
  widgetAppUser: (id) => `${BASE_URL}/app_users/${id}/`,
  appUserAvatar: (id) => `${BASE_URL}/app_users/${id}/avatar/`,
  ranking: `${BASE_URL}/ranking/`,
  rankingUser: `${BASE_URL}/ranking/user/`,
};

export const getTermsLink = (language) => {
  switch (language) {
    case 'fr':
      return 'https://docs.google.com/document/d/1NHvjgllFGh-pBM-27gJmoR8h2oZtAyoKmBflAIrDJbI';
    case 'pt':
      return 'https://docs.google.com/document/d/1XzZsRVRAN4sjnc5Mz1rFW1Qta11vJmZD0GnCeSs9VxM';
    default:
      return 'https://docs.google.com/document/d/1ArFaFSgJrWKTJevRg4ckIfKAE8LVBv3dmHEwGZklWE4';
  }
};

export const COLOR = {
  stoneDark: '#3C464B',
  stoneLight: '#879BAA',
  stoneLight30: '#BECDD7',
  stone2_12: '#E4E4E4',
  stone1_12: '#F5F5F5',
  menuDark: '#9c9c9c',

  darkSkyBlue: '#50BED7',
  accentBlueDark: '#005F87',
  accentBlueLight: '#50BED7',
  accentTeal: '#98D6DD',
  accentTealDark: '#00646E',
  accentTealLight: '#41AAAA',
  accentTeal6_12: '#50BED7',
  accentTealOther: '#55BED7',

  accentYellowDark: '#EB780A',
  accentYellowLight: '#FFB900',
  accentRedDark: '#640946',
  accentRedLight: '#FF6A7E',
  accentGrey: '#AAA5A5',
  accentGreen: '#7EFF6B',

  backgroundColor: '#F4F8F9',
  backgroundColorAdmin: '#333b3f',
  buttonBackgroundPrimary: '#005F87',
  buttonBackgroundPrimaryDisabled: '#E0E0E0',
  lightBackground: '#F8F8F8',

  white: '#FFFFFF',
  black: '#000000',
  black2: '#000300',
  blackish: '#1C1C1C',
  darkGrey: '#26292B',
  grey: '#A4A4A4',
  grey1: '#707070',
  grey2: '#5D5D5D',
  grey3: '#646464',
  grey4: '#d2d2d2',
  grey5: '#A29E9E',
  grey6: '#6f7070',
  grey7: '#f0f0f0',
  grey8: '#a1a3a3',
  grey9: '#a5a5a5',

  red: '#FF0000',
  invalid: '#e2273c',
  lightRed: '#fa544d',
  green: '#3caf6f',
  green2: '#88b955',
  blue: '#358cee',
  blue2: '#1492e5',
  orange: '#f89e14',
  orange2: '#f89c16',

  text: '#343434',
  action: '#FEB205',
  link: '#2680eb',
  checked: 'rgba(38,128,235, 0.07)',

  transparent: 'transparent',

  brownishGrey: '#707070',
  waterBlue: '#1592e6',
  veryLightPink: '#e5e5e5',
  brownGrey: '#a6a6a6',
  yellowishOrange: '#f89e14',
  yellowishOrange2: '#ffaf43',
  heliotrope: '#e05fdb',

  white2: '#f4f4f4',
  white3: '#abaaac',
  adminModeDark: '#172b46',
  defaultPrimaryColor: '#3a4a88',
};

export const INTERACTION_API_TYPE_MAP = {
  voice: 'recording_question',
  vote: 'single_choice_question',
  choice: 'multiple_choice_question',
  sms: 'sms',
  video: 'video',
  text: 'message',
  registration: 'registration',
  game: "game",
};

export const INTERACTION_FRONTEND_TYPE_MAP = {
  multiple_choice_question: 'choice',
  recording_question: 'voice',
  video: 'video',
  registration: 'registration',
  single_choice_question: 'vote',
  message: 'text',
  sms: 'sms',
};

export const INTERACTION_ICON_MAP = {
  voice: <img src="/VoiceInteractionIcon.svg" alt="" />,
  video: <img src="/VideoInteractionIcon.svg" alt="" />,
  vote: <img src="/VoteInteractionIcon.svg" alt="" />,
  choice: <img src="/OtherInteractionIcon.svg" alt="" />,
  sms: <img src="/OtherInteractionIcon.svg" alt="" />,
  registration: <img src="/OtherInteractionIcon.svg" alt="" />,
};

export const INTERACTION_ICON_MAP_WHITE = {
  voice: <img src="/VoiceInteractionIconWhite.svg" alt="" />,
  video: <img src="/VideoInteractionIconWhite.svg" alt="" />,
  vote: <img src="/VoteInteractionIconWhite.svg" alt="" />,
  choice: <img src="/OtherInteractionIconWhite.svg" alt="" />,
  sms: <img src="/OtherInteractionIconWhite.svg" alt="" />,
  registration: <img src="/OtherInteractionIconWhite.svg" alt="" />,
};

export const SEGMENT_ICON_MAP = {
  other: <img src="/Other.svg" alt="" />,
  microphone: <img src="/Microphone.svg" alt="" />,
  vote: <img src="/Vote.svg" alt="" />,
  video: <img src="/Video.svg" alt="" />,
};

export const SAMPLE_BACKGROUNDS = [
  'https://storage.googleapis.com/static-voxm/backgrounds/1.jpg',
  'https://storage.googleapis.com/static-voxm/backgrounds/2.jpg',
  'https://storage.googleapis.com/static-voxm/backgrounds/3.jpg',
  'https://storage.googleapis.com/static-voxm/backgrounds/4.jpg',
  'https://storage.googleapis.com/static-voxm/backgrounds/5.jpg',
  'https://storage.googleapis.com/static-voxm/backgrounds/6.jpg',
  'https://storage.googleapis.com/static-voxm/backgrounds/7.jpg',
  'https://storage.googleapis.com/static-voxm/backgrounds/8.jpg',
];

export const INTERACTION_TYPES = [
  {
    value: INTERACTION_API_TYPE_MAP.voice,
    label: 'Voice message',
    key: 'voice',
  },
  {
    value: INTERACTION_API_TYPE_MAP.video,
    label: 'Video message',
    key: 'video',
  },
  { value: INTERACTION_API_TYPE_MAP.vote, label: 'Vote / survey', key: 'vote' },
  {
    value: INTERACTION_API_TYPE_MAP.choice,
    label: 'Multiple choice game',
    key: 'choice',
  },
  {
    value: INTERACTION_API_TYPE_MAP.registration,
    label: 'Registration game',
    key: 'registration',
  },
  { value: INTERACTION_API_TYPE_MAP.sms, label: 'Sms game', key: 'sms' },
];

export const defaultPagination = {
  hasNext: false,
  hasPrev: false,
  page: 1,
  pages: 0,
  perPage: 50,
  total: 0,
};

export const IMAGE_SIZE = {
  avatar: {
    width: 32,
    height: 32,
  },
  logo: {
    width: 685,
    height: 685,
  },
  background: {
    width: 1125,
    height: 2436,
  },
};

export const STATUSES = [
  { value: 'draft', label: 'Draft' },
  { value: 'ready', label: 'Published' },
  { value: 'archived', label: 'Archived' },
  { value: 'trash', label: 'Trash' },
];

export const BREAKPOINTS = {
  md: 500,
  xl: 1170,
};

export let isMobile = window.innerWidth < BREAKPOINTS.md;
export let isDesktop = window.innerWidth >= BREAKPOINTS.xl;

window.addEventListener('resize', () => {
  isMobile = window.innerWidth < BREAKPOINTS.md;
  isDesktop = window.innerWidth >= BREAKPOINTS.xl;
});

export const daysFr = [
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
  'Dimanche',
];
export const daysPt = [
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
  'Domingo',
];

export const isMobileRegex = detectIsMobile(window.navigator).any;

// Only videos are served directly from cloud. Images are from imgix.
export const MP4_BACKGROUND_STR = 'https://storage.googleapis.com/';
export const STATIC_BUCKET = '/static-voxm/';

export const DATE_FORMAT = 'dddd DD MMMM YYYY';
export const TIME_FORMAT = 'HH [h] mm';

export const WITH_PIMPMYCOMPANY_HOOK = true;
export const WITH_EVOL_RTL = true;
